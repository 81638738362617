const FILTER_BASE = {
  services: null,
  types: [],
  states: [],
  search: '',
  client_id: null,
  organization_uuid: null,
  operator_id: null,
  ordering: null,
  lang: null,
  date: 'all',
};

export const state = () => ({
  requests: [],
  ongoingRequests: [],
  pendingRequests: [],
  isPendingRequestsLoading: false,
  request: {
    contacts: [],
    files: [],
  },
  newWebsocketRequests: [],
  filter: FILTER_BASE,
  days: [],
  perPage: 20,
  toForward: null,
  forwarded: null,
  invitedAssistant: null,
  invitedAssistantResponse: null,
  page: {
    total: 0,
    current: 1,
    hasPrevious: false,
    hasNext: false,
  },
});

const parseRequestDates = (payload) => {
  const request = { ...payload };

  request.has_book_time = !!request.book_time && request.book_time !== request.created_at;
  request.book_time = request.book_time ? request.book_time : request.created_at;

  request.day = dayjs(request.book_time).format('YYYY-MM-DD');

  return request;
};

export const mutations = {
  REQUESTS_SET(state, payload) {
    state.requests = payload;
  },
  REQUEST_SET(state, payload) {
    if (payload) {
      const index = state.requests.findIndex((r) => r.id == payload.id);
      if (index != -1) {
        state.requests.splice(index, 1, payload);
      } else {
        state.requests = [...state.requests, payload];
      }
    }

    state.request = payload;
  },
  REQUEST_ADD(state, payload) {
    state.requests.push(payload);
  },
  ONGOING_REQUEST_ADD(state, payload) {
    state.ongoingRequests.push(payload);
  },
  PENDING_REQUESTS_SET(state, payload) {
    state.pendingRequests = payload;
  },
  PENDING_REQUESTS_LOADING_SET(state, payload) {
    state.isPendingRequestsLoading = payload;
  },
  REQUEST_REMOVE(state, id) {
    state.requests = state.requests.filter((request) => request.id !== id);
  },
  ONGOING_REQUESTS_SET(state, payload) {
    state.ongoingRequests = payload;
  },
  REQUEST_UPDATE(state, { id, data }) {
    const newData = { ...data };

    const index = state.requests.findIndex((r) => r.id == id);
    if (index != -1) {
      const found = { ...state.requests[index] };

      // rename the incoming var, in case we update the whole attachments array separately
      if (newData.new_attachment) {
        if (!newData.attachments) { newData.attachments = [] }

        newData.new_attachments_count = (newData.new_attachments_count || 0) + 1;

        newData.attachments = [...newData.attachments, newData.new_attachment];
        delete newData.new_attachment;
      }

      // join the two attachments arrays
      if (found.attachments && newData.attachments) {
        newData.attachments = [...new Map([...found.attachments, ...newData.attachments].map((item) => [(item.id || item.uid), item])).values()]; // filter out duplicities
      }

      const payload = { ...found, ...newData };

      state.requests.splice(index, 1, payload);

      state.requests = state.requests.map(parseRequestDates);
    }

    state.ongoingRequests = state.ongoingRequests.map((request) => {
      if (request.id === id) {
        request = { ...request, ...data };
      }

      return request;
    });

    if (state.request && state.request.id === id) {
      state.request = { ...state.request, ...data };
    }
  },

  REQUEST_UPDATE_USER(state, { id, data }) {
    state.requests = state.requests.map((request) => {
      if (request.client && request.client.id === id) {
        request.client = {
          ...request.client,
          ...data,
        };
      }

      return request;
    });

    if (state.request && state.request.client && state.request.client.id === id) {
      state.request.client = {
        ...state.request.client,
        ...data,
      };
    }
  },

  DAYS_SET(state, payload) {
    state.days = payload;
  },
  PAGE_UPDATE(state, payload) {
    state.page = { ...state.page, ...payload };
  },
  FILTER_UPDATE(state, payload) {
    state.filter = { ...state.filter, ...payload };
  },
  FILTER_RESET(state) {
    state.filter = FILTER_BASE;
  },
  TO_FORWARD_SET(state, payload) {
    state.toForward = payload;
  },
  FORWARDED_SET(state, payload) {
    state.forwarded = payload;
  },
  INVITE_ASSISTANT_SET(state, payload) {
    state.invitedAssistant = payload;
  },
  INVITE_ASSISTANT_RESPONSE_SET(state, payload) {
    state.invitedAssistantResponse = payload;
  },
  NEW_WEBSOCKET_REQUESTS_SET(state, payload) {
    state.newWebsocketRequests = payload;
  },
  NEW_WEBSOCKET_REQUESTS_ADD(state, payload) {
    state.newWebsocketRequests = [...state.newWebsocketRequests, payload];
  },
};

export const actions = {
  async getNewEmergencyRequests({ commit, state, rootState }) {
    try {
      const response = await useApi.get('/operator/requests', {
        params: {
          states: '1',
          types: rootState.requests.EMERGENCY_TYPE_ID,
        },
      });

      const requests = response.results.map(parseRequestDates);

      return requests;
    } catch (error) {
      ElMessage.error('Chyba při načítání požadavků.');
      console.error(error);
    }
  },

  async getOngoingRequests({ commit, state }) {
    try {
      const response = await useApi.get('/operator/requests', {
        params: {
          states: '2,3',
          operator_id: useAuthStore().user?.id,
        },
      });

      const requests = response.results.map(parseRequestDates);

      commit('ONGOING_REQUESTS_SET', requests);
    } catch (error) {
      ElMessage.error('Chyba při načítání požadavků.');
      console.error(error);
    }
  },

  async getRequests({ commit, dispatch, state }) {
    try {
      const response = await useApi.get('/operator/requests', {
        params: {
          limit: state.perPage * state.page.current,
          services: state.filter.services ? [state.filter.services].join(',') : undefined,
          types: state.filter.types.length ? [...state.filter.types].join(',') : undefined,
          states: state.filter.states.length ? [...state.filter.states].join(',') : undefined,
          q: state.filter.search.length > 2 ? state.filter.search : undefined,
          client_id: state.filter.client_id || undefined,
          organization_uuid: state.filter.organization_uuid || undefined,
          ordering: state.filter.ordering || undefined,
          operator_id: state.filter.operator_id || undefined,
          lang: state.filter.lang || undefined,
        },
      });

      let requests = response.results.map(parseRequestDates);

      if (state.filter.date === 'today') {
        requests = requests.filter((request) => dayjs().isSameOrAfter(request.book_time, 'day'));
      }

      // Check emergency requests
      if (state.filter.date === 'history') {
        const emergencyRequests = await dispatch('getNewEmergencyRequests');

        if (emergencyRequests && emergencyRequests.length) {
          requests = [...emergencyRequests, ...requests];
        }
      }

      commit('REQUESTS_SET', requests);
      commit('PAGE_UPDATE', { hasPrevious: !!response.previous, hasNext: !!response.next, total: response.count });
    } catch (error) {
      ElMessage.error('Chyba při načítání požadavků.');
      console.error(error);
    }
  },

  addRequest({ commit }, request) {
    commit('REQUEST_ADD', parseRequestDates(request));

    commit('NEW_WEBSOCKET_REQUESTS_ADD', request);
  },

  removeRequest({ commit }, requestId) {
    if (!useRoute().name?.includes('operator-requests-id-chat')) {
      commit('REQUEST_REMOVE', requestId);
    } else {
      console.warn(`The client has deleted the request (${requestId})`);
    }
  },

  async loadMoreRequests({ commit, dispatch, state }) {
    commit('PAGE_UPDATE', { current: state.page.current + 1 });

    await dispatch('getRequests');
  },

  async getPendingRequests({ state, commit }) {
    if (state.isPendingRequestsLoading) {
      console.warn('Pending requests are already loading');
      return;
    }

    try {
      commit('PENDING_REQUESTS_LOADING_SET', true);
      const response = await useApi.get('/operator/requests/pending', {
        params: {
          service_id: state.filter.services ? [state.filter.services].join(',') : undefined,
        },
      });
      const requests = response.map(parseRequestDates);

      commit('PENDING_REQUESTS_LOADING_SET', false);
      commit('PENDING_REQUESTS_SET', requests);
    } catch (error) {
      ElMessage.error('Chyba při načítání požadavků.');
      console.error(error);
      commit('PENDING_REQUESTS_LOADING_SET', false);
    }
  },

  async getRequest({ commit }, id) {
    const request = await useApi.get(`/operator/requests/${id}`);

    commit('REQUEST_SET', request);
  },

  async updateRequest({ commit }, { id, data, forceUpdate = false }) {
    const request = await useApi.patch(`/operator/requests/${id}`, {
      body: data,
    });

    commit('REQUEST_UPDATE', {
      id,
      data: forceUpdate ? request : data,
    });
  },
};

export const getters = {
  getDashboardRequests: (state) => {
    return state.requests.filter((request) => {
      if (request.operator || request.operator_id) {
        return false;
      }

      if (state.filter.services && state.filter.services !== request.service_id) {
        return false;
      }

      if (state.filter.types.length && !state.filter.types.includes(request.type_id)) {
        return false;
      }

      if (state.filter.lang && state.filter.lang !== request.client?.lang) {
        return false;
      }

      return true;
    });
  },

  getOngoingRequests: (state) => (operatorId) => {
    const websocketOperatorRequests = state.requests.filter((request) => (request.state_id === 2 || request.state_id === 3) && !state.ongoingRequests.find((ongoingRequest) => ongoingRequest.id === request.id));

    return [...websocketOperatorRequests, ...state.ongoingRequests].filter((request) => (request.operator && request.operator.id === operatorId));
  },

  getDays: (state, getters, rootState) => {
    const requestsWithoutEmergency = getters.getDashboardRequests.filter((request) => request.type_id !== rootState.requests.EMERGENCY_TYPE_ID);
    const uniqueDays = [...new Set((requestsWithoutEmergency).map((request) => request.day))];

    return uniqueDays.sort((a, b) => dayjs(a).format('YYYYMMDD') - dayjs(b).format('YYYYMMDD'));
  },

  getHistoryRequests: (state) => {
    return state.requests.filter((request) => {
      if (state.filter.services && state.filter.services !== request.service_id) {
        return false;
      }

      if (state.filter.types.length && !state.filter.types.includes(request.type_id)) {
        return false;
      }

      return true;
    });
  },

  isRequestAcceptedByAnotherOperator: (state) => {
    return (request, user) => {
      if (request?.assistant?.id === user?.id) {
        return false;
      }

      return request?.operator && request?.operator?.id !== user?.id && request?.state_id === 2;
    };
  },

  canGetTokboxTokens: (state) => {
    return (request, user) => {
      if (!user?.is_operator) { // client
        return true;
      }

      if (request?.assistant?.id === user?.id) { // assistant
        return true;
      }

      return request?.operator?.id === user.id; // assigned operator
    };
  },
};
